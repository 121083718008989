import { useEffect, useRef } from "react";
import "./VideoComponent.css";

function VideoComponent({ track, participantIdentity, local = false,isVideoEnabled ,onVideoClick}) {
    const videoElement = useRef(null);

    useEffect(() => {
        if (track && videoElement.current) {
            // Attach the track to the video element
            track.attach(videoElement.current);
        }

        return () => {
            // Detach the track when the component unmounts or the track changes
            if (track) {
                track.detach();
            }
        };
    }, [track]);

    return (
        <div id={"camera-" + participantIdentity} className="video-container"  onClick={() => onVideoClick(videoElement.current)}>
            {track ? (
                <>
                    {/* Render the video element if the track is available */}
                    <video
                        ref={videoElement}
                        id={track.sid}
                        autoPlay
                        playsInline
                        muted={local} // Mute local participant’s video
                    ></video>
                    {/* Participant name at top-left */}
                  {isVideoEnabled &&
                    <div className="participant-data top-left">
                        <p>{(participantIdentity)?.split("&")[1] + (local ? " (You)" : "")}</p>
                    </div>
                       }
                </>
            ) : (
                // Render the participant name in the center if no video track
                <div className="no-video-placeholder">
                    <p>{participantIdentity + (local ? " (You)" : "")}</p>
                </div>
            )}
        </div>
    );
}

export default VideoComponent;
